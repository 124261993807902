










































































































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { SettingSolanaWalletViewModel } from '@/modules/ido/viewmodels/setting-solana-wallet-viewmodel'

@Observer
@Component({
  components: {}
})
export default class IdoPoolDetail extends Vue {
  @Provide() vm = new SettingSolanaWalletViewModel()
  walletStore = walletStore
  address = ''
}
